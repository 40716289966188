import React from 'react';

import styles from './index.module.less';
import { tagList } from '../constant';
import Link from 'next/link';

const PcTag: React.FC = () => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.list}>
                    {tagList.map((item) => {
                        return (
                            <Link key={item.id} href={item.url} className={styles.item}>
                                <img loading="lazy" src={item.img} alt={`${item.alt} logo`} />
                            </Link>
                        );
                    })}
                </div>
            </div>
        </>
    );
};
export default PcTag;
