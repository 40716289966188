import type { ReactNode } from 'react';
import React from 'react';

import styles from './index.module.less';

interface IProps {
    title?: ReactNode;
    desc?: ReactNode;
    mTitle?: ReactNode;
}
const PcTitle: React.FC<IProps> = (props) => {
    const { title, desc, mTitle } = props;
    return (
        <div className={styles.container}>
            {title && (
                <div className={styles.title} dangerouslySetInnerHTML={{ __html: (mTitle || title) as string }} />
            )}
            {desc && <div className={styles.desc} dangerouslySetInnerHTML={{ __html: desc as string }} />}
        </div>
    );
};
export default PcTitle;
