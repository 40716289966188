import React from 'react';

import IpadBanner from './ipad';
import PcBanner from './pc';
import TransferCom from '../transferCom';

const IdcTag: React.FC = () => {
    return (
        <>
            <TransferCom pc={<PcBanner />} m={null} ipad={<IpadBanner />} />
        </>
    );
};
export default IdcTag;
