import React from 'react';

import IpadBanner from './ipad';
import PcBanner from './pc';
import TransferCom from '../transferCom';

const CloudTag: React.FC = () => {
    return <TransferCom pc={<PcBanner />} m={null} ipad={<IpadBanner />} />;
};
export default CloudTag;
