import { Button, Space } from 'antd';
import type { ReactNode } from 'react';
import React from 'react';
import intl from 'react-intl-universal';

import styles from './index.module.less';
import CloudTag from '../../cloudTag';
import IdcTag from '../../IdcTag';
import classNames from 'classnames';
import { useRouter } from 'next/router';
interface IProps {
    title?: string | ReactNode;
    pcDesc?: string | ReactNode;
    pcSrc?: string;
    btnText?: string;
    type?: 'video' | 'img';
    videoSrc?: string;
    poster?: string;
    pcStyle?: React.CSSProperties;
    isShowCloudTag?: boolean;
    isHideBtn?: boolean;
    isShowIdcTag?: boolean;
    isShowBtn2?: boolean;
    btnRender?: ReactNode;
    pcTitleStyle?: React.CSSProperties;
    isNeedGoTo?: boolean;
}

const PcBanner: React.FC<IProps> = (props) => {
    const {
        title,
        pcDesc,
        btnText,
        type,
        poster,
        videoSrc,
        pcSrc,
        pcStyle,
        isShowCloudTag,
        isShowIdcTag,
        isHideBtn,
        isShowBtn2,
        btnRender,
        pcTitleStyle,
        isNeedGoTo,
    } = props;
    const router = useRouter();
    return (
        <>
            <div
                className={classNames(styles.container, isNeedGoTo && styles['con-cur'])}
                onClick={() => {
                    if (!isNeedGoTo) return;
                    router.push('/products/clouddesk/');
                }}
                aria-hidden="false"
            >
                <div className={styles.text}>
                    {typeof title === 'string' ? (
                        <div
                            className={styles.title}
                            style={{ ...pcTitleStyle }}
                            dangerouslySetInnerHTML={{ __html: title }}
                        />
                    ) : (
                        title
                    )}
                    <div className={styles.desc} dangerouslySetInnerHTML={{ __html: pcDesc as string }} />
                    {btnRender ||
                        (!isHideBtn && (
                            <Space>
                                <Button
                                    type="primary"
                                    size="large"
                                    className={styles.btn}
                                    onClick={() => {
                                        router.push('/contact/');
                                    }}
                                >
                                    {btnText || intl.get('Common.BannerBtnText').d('立即了解')}
                                </Button>
                                {isShowBtn2 && (
                                    <Button
                                        type="primary"
                                        size="large"
                                        className={styles.btn}
                                        onClick={() => {
                                            router.push('/contact/');
                                        }}
                                    >
                                        {intl.get('Common.BannerBtnText2').d('联系销售')}
                                    </Button>
                                )}
                            </Space>
                        ))}
                </div>

                {type === 'video' ? (
                    <div style={{ width: '100%', ...pcStyle }}>
                        <video autoPlay loop controls={false} muted poster={poster} preload="metadata">
                            <source src={videoSrc} type="video/mp4" />
                        </video>
                    </div>
                ) : (
                    <div className={styles['banner-box']} style={{ width: '100%', ...pcStyle }}>
                        <img className={styles['banner-img']} src={pcSrc} alt="" />
                    </div>
                )}
                {isShowCloudTag && (
                    <div className={styles['tag-con']}>
                        <CloudTag />
                    </div>
                )}
                {isShowIdcTag && (
                    <div className={styles['tag-con']}>
                        <IdcTag />
                    </div>
                )}
            </div>
        </>
    );
};

export default PcBanner;
