import classnames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';

import styles from './index.module.less';
import { idcTagList } from '../constant';
import Link from 'next/link';

const PcTag: React.FC = () => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.list}>
                    {idcTagList.map((item) => {
                        return (
                            <Link key={item.id} href={item.url} className={classnames(styles.item)}>
                                <span className={styles['item-tit']}>{intl.get(item.title)}</span>
                            </Link>
                        );
                    })}
                </div>
            </div>
        </>
    );
};
export default PcTag;
