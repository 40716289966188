import React from 'react';
import classnames from 'classnames';
import type { ReactNode } from 'react';
import styles from './index.module.less';
import { useRouter } from 'next/router';
// import { type CarouselListItem } from '../type';
// import { Carousel } from 'antd';

interface IProps {
    title?: string | ReactNode;
    mTitle?: string | ReactNode;
    mDesc?: string | ReactNode;
    mSrc?: string;
    mStyle?: React.CSSProperties;
    mTextCls?: string;
    btnRender?: ReactNode;
    mShowBtn?: boolean;
    mTitleStyle?: React.CSSProperties;
    isNeedGoTo?: boolean;
}

const MBanner: React.FC<IProps> = (props) => {
    const { mTitle, title, mDesc, mSrc, mStyle, btnRender, mTextCls, mShowBtn, mTitleStyle, isNeedGoTo } = props;
    const router = useRouter();
    return (
        <>
            <div
                className={classnames(styles.container, mShowBtn ? styles['has-btn'] : null)}
                onClick={() => {
                    if (!isNeedGoTo) return;
                    router.push('/products/clouddesk/');
                }}
            >
                <div className={classnames(styles.text, mTextCls)}>
                    {mTitle
                        ? null
                        : title && (
                              <div
                                  className={styles.title}
                                  style={{ ...mTitleStyle }}
                                  dangerouslySetInnerHTML={{ __html: title }}
                              />
                          )}
                    {mTitle && typeof mTitle === 'string' ? (
                        <div
                            className={styles.title}
                            style={{ ...mTitleStyle }}
                            dangerouslySetInnerHTML={{ __html: mTitle }}
                        />
                    ) : (
                        mTitle
                    )}
                    <div className={styles.desc} dangerouslySetInnerHTML={{ __html: mDesc as string }} />
                </div>

                {mShowBtn && <div className={styles['btn-box']}>{btnRender}</div>}
                <div className={styles['banner-box']} style={{ ...mStyle, backgroundImage: `url(${mSrc})` }}></div>
            </div>
        </>
    );
};
export default MBanner;
