import { Button } from 'antd';
import type { ReactNode } from 'react';
import React from 'react';
import intl from 'react-intl-universal';
import { useRouter } from 'next/router';

import styles from './index.module.less';
import CloudTag from '../../cloudTag';
import IdcTag from '../../IdcTag';

interface IProps {
    title?: string | ReactNode;
    ipadDesc?: string | ReactNode;
    ipadSrc?: string;
    btnText?: string;
    type?: 'video' | 'img';
    isShowCloudTag?: boolean;
    isShowIdcTag?: boolean;
    isHideBtn?: boolean;
}
const IpadBanner: React.FC<IProps> = (props) => {
    const { title, ipadDesc, btnText, ipadSrc, isShowCloudTag, isShowIdcTag, isHideBtn } = props;
    const router = useRouter();
    return (
        <>
            <div className={styles.container}>
                <div className={styles.text}>
                    <div className={styles.title} dangerouslySetInnerHTML={{ __html: title as string }} />
                    <div className={styles.desc} dangerouslySetInnerHTML={{ __html: ipadDesc as string }} />
                    {!isHideBtn && (
                        <Button
                            type="primary"
                            className={styles.btn}
                            onClick={() => {
                                router.push('/contact/');
                            }}
                        >
                            {btnText || intl.get('Common.BannerBtnText').d('立即选购')}
                        </Button>
                    )}
                </div>
                <div className={styles['banner-box']} style={{ backgroundImage: `url(${ipadSrc})` }}></div>
                {isShowCloudTag && (
                    <div className={styles['tag-con']}>
                        <CloudTag />
                    </div>
                )}
                {isShowIdcTag && (
                    <div className={styles['tag-con']}>
                        <IdcTag />
                    </div>
                )}
            </div>
        </>
    );
};
export default IpadBanner;
