export const idcTagList = [
  {
    id: '01',
    title: 'Menu.ServerHosting',
    desc: 'Menu.IDCBannerTag1',
    url: '/products/serverhosting/'
  },
  {
    id: '02',
    title: 'Menu.ServerRent',
    desc: 'Menu.IDCBannerTag2',
    url: '/products/serverrental/'
  },
  {
    id: '03',
    title: 'Menu.ServerProcurement',
    desc: 'Menu.IDCBannerTag3',
    url: '/products/serverprocure/'
  },
  {
    id: '04',
    title: 'Menu.CabinetRental',
    desc: 'Menu.IDCBannerTag4',
    url: '/products/racksrental/'
  },
]
